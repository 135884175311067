import React, { useState} from 'react';
import Navbar from '../components/Navbar.js';
import '../css/oddsmatcher.css';

const Oddsmatcher = () => { 
    const [visibleRow, setVisibleRow] = useState(null);
    const toggleDetails = (index) => {
        setVisibleRow(visibleRow === index ? null : index);
    };

    // Datos de ejemplo
    const events = [
        {
            fecha: '01/01/2024 12:00',
            evento: 'Barca vs Madrid',
            mercado: '+2.5 goles',
            rating: '90%',
            bookie: 'Bet365',
            cuota1: '2.00',
            cuota2: '5.00',
        },
        {
            fecha: '01/01/2024 15:00',
            evento: 'Event 2',
            mercado: 'Market 2',
            rating: '85%',
            bookie: 'Bookmaker 2',
            cuota1: '1.80',
            cuota2: '4.50',
        }
    ];

    return (
        <div>
            <Navbar />
            <div className="main-container">
                <h1>Oddsmatcher goes here!</h1>
                <table className="table-odds">
                    <thead>
                        <tr>
                            <th>Fecha/Hora</th>
                            <th>Evento</th>
                            <th>Mercado</th>
                            <th>Rating</th>
                            <th>Bookie</th>
                            <th>Cuota 1</th>
                            <th>Cuota Exchange</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event, index) => (
                            <React.Fragment key={index}>
                                <tr onClick={() => toggleDetails(index)}>
                                    <td>{event.fecha}</td>
                                    <td>{event.evento}</td>
                                    <td>{event.mercado}</td>
                                    <td>{event.rating}</td>
                                    <td>{event.bookie}</td>
                                    <td>{event.cuota1}</td>
                                    <td>{event.cuota2}</td>
                                </tr>
                                {visibleRow === index && (
                                    <tr className="details-row">
                                        Ganarás {event.rating} dinero 
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Oddsmatcher;