import React, { useContext } from 'react';
import Navbar from '../components/Navbar.js';
import { Link } from 'react-router-dom';
import { UserContext } from '../components/UserContext.js';
import '../css/inicio.css'; // Importa el archivo CSS

const Inicio = () => {
  //const { userId } = useContext(UserContext); // Accede al contexto, por si necesitas usar userId en este componente

  return (
    <div>
      <Navbar /> {/* No es necesario pasar userId como prop */}
      <div className="main-container">
        <h1>PandaBet</h1>
        <p>
        Bienvenido a PandaBet, la plataforma líder para quienes buscan aprovechar al máximo el mundo de las apuestas deportivas a través del matched betting y el arbitraje de apuestas. Nuestro objetivo es ayudarte a generar ingresos consistentes y seguros, eliminando el riesgo asociado con las apuestas tradicionales.        </p>
        {/* Si userId es null, muestra el botón de registro */}
        <div className="button-container">
          <Link to="/mbetting" className="button">Matched Betting</Link> 
          <Link to="/arbbetting" className="button">Arbitraje</Link>
        </div>
        
      </div>
     
    </div>
  );
};

export default Inicio;